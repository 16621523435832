export const ProjectsEvents = {
    projectsLoaded: 'projects',
    clickProject: 'project',
    deleteProject: 'delete project',
    exportProject: 'export project'
};

export const CatalogEvents = {
    catalogLoaded: 'catalog',
    categoryClicked: 'catalog category',
    templateClicked: 'catalog template'
};

export const EditorInstructionsEvents = {
    loaded: 'instructions modal loaded',
    nextStepClicked: 'instructions modal next step',
    previousStepClicked: 'instructions modal next step',
    doneClicked: 'instructions modal done',
    dismissClicked: 'instructions modal dismiss'
};

export const EditorEvents = {
    loaded: 'editor page loaded',
    instructionsClicked: 'editor instructions modal',
    resetClicked: 'editor reset',
    saveClicked: 'editor save',
    checkoutClicked: 'editor checkout'
};

export const LandingPageEvents = {
    loaded: 'landing page loaded',
    startClick: 'landing page start clicked',
    contactUsClick: 'landing page contact us clicked'
};

export const CheckoutPageEvents = {
    loaded: 'checkout page loaded',
    checkoutClicked: 'checkout page checkout clicked'
};

export const PaymentSucesssEvents = {
    loaded: 'payment success page loaded'
};

export const PrintPageEvents = {
    loaded: 'print page loaded',
    downloadClicked: 'print page download clicked'
};

export const ContactUsPageEvents = {
    loaded: 'contact us page loaded',
    sendClick: 'contact us form send clicked',
    sendSuccess: 'contact us form send success',
    sendFailed: 'contact us form send failed'
};

export const AboutPageEvents = {
    loaded: 'about page loaded'
};

export const SignInPageEvents = {
    loaded: 'sign in page loaded'
};
