import HNText from '@/components/HNText';
import { styled } from 'styled-components';
import { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import ImageStrip from '@/components/landing/ImageStrip';
import Carousel from '@/components/landing/Carousel';
import { AlefFont, Fonts, SecularOneFont } from '@/styles/fonts';
import WhyUsSection from '@/components/landing/WhyUsSection';
import HowItWorksSection from '@/components/landing/HowItWorksSection';
import Lottie from 'lottie-react';
import AnimationData from '@/components/animations/scroll.json';
import config from '@/lib/config';
import HNButton from '@/components/HNButton';
import { Image } from '@nextui-org/react';
import useAnalytics from '@/hooks/useAnalytics';
import { LandingPageEvents } from '@/lib/types/mixpanel-events';
import { colors } from '@/styles/colors';

const LandingPage = () => {
    const router = useRouter();
    const { track } = useAnalytics();
    const buttonsContainerRef = useRef(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const [viewHeight, setViewHeight] = useState(0);
    const [isSticky, setIsSticky] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);

    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const whyChooseRef = useRef(null);
    const howItWorksRef = useRef(null);

    const [titleInView, setTitleInView] = useState(false);
    const [subtitleInView, setSubtitleInView] = useState(false);
    const [whyChooseInView, setWhyChooseInView] = useState(false);
    const [howItWorksInView, setHowItWorksInView] = useState(false);

    useEffect(() => {
        track({
            eventName: LandingPageEvents.loaded,
            eventType: 'load'
        });
    }, [track]);

    useEffect(() => {
        let titleRefValue = null;
        let subtitleRefValue = null;
        let whyChooseRefValue = null;
        let howItWorksRefValue = null;

        const createObserver = (setState: React.Dispatch<React.SetStateAction<boolean>>, threshold = 0.3) => {
            return new IntersectionObserver(([entry]) => setState(entry.isIntersecting), { threshold });
        };

        const titleObserver = createObserver(setTitleInView);
        const subtitleObserver = createObserver(setSubtitleInView);
        const whyChooseObserver = createObserver(setWhyChooseInView);
        const howItWorksObserver = createObserver(setHowItWorksInView, 0.1);

        if (titleRef.current) {
            titleObserver.observe(titleRef.current);
            titleRefValue = titleRef.current;
        }
        if (subtitleRef.current) {
            subtitleObserver.observe(subtitleRef.current);
            subtitleRefValue = subtitleRef.current;
        }
        if (whyChooseRef.current) {
            whyChooseObserver.observe(whyChooseRef.current);
            whyChooseRefValue = whyChooseRef.current;
        }
        if (howItWorksRef.current) {
            howItWorksObserver.observe(howItWorksRef.current);
            howItWorksRefValue = howItWorksRef.current;
        }

        return () => {
            if (titleRefValue) titleObserver.unobserve(titleRefValue);
            if (subtitleRefValue) subtitleObserver.unobserve(subtitleRefValue);
            if (whyChooseRefValue) whyChooseObserver.unobserve(whyChooseRefValue);
            if (howItWorksRefValue) howItWorksObserver.unobserve(howItWorksRefValue);
        };
    }, []);

    useEffect(() => {
        if (containerRef.current) setViewHeight(containerRef.current.clientHeight);
    }, [containerRef?.current?.clientHeight]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const threshold = 50;

            if (scrollY > threshold && !isSticky) {
                setIsSticky(true);
            } else if (scrollY <= threshold && isSticky) {
                setIsSticky(false);
            }

            if (scrollY > viewHeight - 900) {
                setIsAtBottom(true);
            } else {
                setIsAtBottom(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isSticky, isAtBottom, viewHeight]);

    const startClicked = () => {
        track({
            eventName: LandingPageEvents.startClick,
            eventType: 'click'
        });
        router.push('/catalog');
    };

    const contactClicked = () => {
        track({
            eventName: LandingPageEvents.contactUsClick,
            eventType: 'click'
        });
        router.push('/contact');
    };

    return (
        <Container ref={containerRef}>
            <InnerContainer>
                <TopContainer>
                    <StyledH1Header>
                        הזמנות לחתונה - הזמנות לבר מצווה - הזמנות לבת מצווה - הזמנות לחינה - הזמנות לברית - הזמנות לימי הולדת
                    </StyledH1Header>
                    <TopContainerContent>
                        <TitleContainer>
                            <Title ref={titleRef} $inView={titleInView} className={SecularOneFont.className}>
                                הזמנינא
                            </Title>
                            <Subtitle ref={subtitleRef} $inView={subtitleInView} className={AlefFont.className}>
                                הפלטפורמה המתקדמת בישראל לעיצוב הזמנות לאירועים.
                                <br />
                                פשוטה, מהירה ועם מגוון תבניות מושלמות לכל סגנון ואירוע.
                            </Subtitle>
                            <ButtonsContainer ref={buttonsContainerRef} $isSticky={isSticky} $isAtBottom={isAtBottom}>
                                <StyledButton style="lg" font={Fonts.Alef} onClick={startClicked}>
                                    עצבו הזמנה
                                </StyledButton>
                                <StyledButton style="lg-secondary" font={Fonts.Alef} onClick={contactClicked}>
                                    צור קשר
                                </StyledButton>
                            </ButtonsContainer>
                        </TitleContainer>
                        <StyledCarousel />
                    </TopContainerContent>
                    <MWebMessage className={AlefFont.className}>יש להיכנס דרך המחשב</MWebMessage>
                    <StyledLogo src="/smiling-letter.png" alt="hazmanina" width={100} height={100} />
                    <StyledScrollAnimation animationData={AnimationData} className="flex justify-center items-center" loop={true} />
                </TopContainer>
                <Section ref={whyChooseRef} $inView={whyChooseInView}>
                    <WhyUsSection inView={whyChooseInView} />
                </Section>
                <Section ref={howItWorksRef} $inView={howItWorksInView}>
                    <HowItWorksSection inView={howItWorksInView} />
                </Section>
                <StyledStrip />
            </InnerContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const InnerContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    direction: rtl;
    margin-block-end: 100px;

    @media screen and (max-width: 872px) {
        flex-direction: column;
        justify-content: flex-start;
        margin-block-end: 40px;
        width: 100%;
    }
`;

const Section = styled.div<{ $inView: boolean }>`
    width: 100%;
    margin-block-start: 50px;
    transform: ${({ $inView }) => ($inView ? 'translateY(0)' : 'translateY(10px)')};
    opacity: ${({ $inView }) => ($inView ? 1 : 0)};
    transition: opacity 1s ease, transform 1s ease;

    @media screen and (max-width: 872px) {
        display: none;
    }
`;

const TopContainer = styled.div`
    width: 100%;
    max-width: 1000px;
    height: calc(100vh - ${config.ui.navbarHeight}px);
    min-height: 700px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 872px) {
        height: auto;
    }
`;

const StyledH1Header = styled.h1`
    margin-inline-start: 10px;
    margin-inline-end: 10px;

    margin-block-start: 10px;
    margin-block-end: 50px;

    font-size: 18px;
    line-height: 18px;
    font-weight: bold;

    padding: 8px;
    border-radius: 6px;
    background-color: ${colors.offWhite};
    border-width: 1px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    @media screen and (max-width: 872px) {
        margin-block-start: 0px;
        margin-block-end: 40px;
        text-align: center;
    }
`;

const TopContainerContent = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 450px;

    @media screen and (max-width: 872px) {
        flex-direction: column;
        justify-content: flex-start;
        margin-block-end: 50px;
        margin-block-start: -30px;
    }
`;

const StyledLogo = styled(Image)`
    width: 100px;
    height: 100px;
    margin-block-start: 20px;

    @media screen and (min-width: 873px) {
        display: none;
    }
`;

const ButtonsContainer = styled.div<{ $isSticky: boolean; $isAtBottom: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    direction: rtl;
    gap: 10px;
    margin-block-start: 60px;

    position: ${({ $isSticky, $isAtBottom }) => ($isSticky || $isAtBottom ? 'fixed' : 'relative')};
    bottom: ${({ $isSticky }) => ($isSticky ? '20px' : 'auto')};
    ${({ $isAtBottom }) => ($isAtBottom ? `bottom: ${config.ui.bottomBarHeight + 40}px` : '')};
    transform: ${({ $isSticky, $isAtBottom }) => ($isSticky || $isAtBottom ? 'translateX(-75%)' : '')};
    transition: bottom 0.3s ease, transform 0.3s ease;
    z-index: 20;

    @media screen and (max-width: 872px) {
        display: none;
    }
`;

const StyledButton = styled(HNButton)`
    width: 200px;

    @media screen and (max-width: 872px) {
        width: 80%;
        align-self: center;
    }
`;

const StyledScrollAnimation = styled(Lottie)`
    @media screen and (max-width: 872px) {
        display: none;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-block-end: 90px;

    @media screen and (max-width: 872px) {
        align-items: center;
        margin-block-end: 30px;
        width: 80%;
    }
`;

const Title = styled(HNText)<{ $inView: boolean }>`
    font-size: 100px;
    line-height: 1;
    margin-block-end: 10px;
    opacity: ${({ $inView }) => ($inView ? 1 : 0)};
    transition: opacity 1s ease, transform 1s ease;

    @media screen and (min-width: 873px) {
        transform: ${({ $inView }) => ($inView ? 'translateX(0)' : 'translateX(100px)')};
    }

    @media screen and (max-width: 872px) {
        margin-block-end: 30px;
    }
`;

const Subtitle = styled(HNText)<{ $inView: boolean }>`
    font-size: 20px;
    opacity: ${({ $inView }) => ($inView ? 0.85 : 0)};
    transform: ${({ $inView }) => ($inView ? 'translateX(0)' : 'translateX(-100px)')};
    transition: opacity 1s ease, transform 1s ease;

    @media screen and (max-width: 872px) {
        text-align: center;
        font-size: 20px;
        opacity: 0.85;
    }
`;

const MWebMessage = styled(HNText)`
    font-size: 22px;
    font-weight: 600;

    @media screen and (min-width: 872px) {
        display: none;
    }
`;

const StyledStrip = styled(ImageStrip)`
    margin-block: 100px 80px;

    @media screen and (max-width: 872px) {
        display: none;
    }
`;

const StyledCarousel = styled(Carousel)`
    height: min(500px, 100%);
    aspect-ratio: 5 / 7;

    @media screen and (max-width: 872px) {
        width: 80%;
        height: auto;
    }
`;

export default LandingPage;
